.nav-links {
    font-size:medium;
    @apply font-bold;
}
.nav-text-gradient {
    background: linear-gradient(var(--mauve), var(--mauve));
    -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: gradient-animation222 infinite 20s linear;
}
@keyframes gradient-animation222 {
    0% {
        background: linear-gradient(var(--mauve), var(--blue));
        -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    20% {
        background: linear-gradient(var(--blue), var(--red));
        -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    40% {
        background: linear-gradient(var(--red), var(--pink));
        -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    60% {
        background: linear-gradient(var(--pink), var(--rosewater));
        -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    80% {
        background: linear-gradient(var(--rosewater), var(--flamingo));
        -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    100% {
        background: linear-gradient(var(--mauve), var(--pink));
        -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
}