.animate-404 {
    animation: App-logo-spin infinite 20s linear;
}
@keyframes App-logo-spin {

    from {
  
      transform: rotate(0deg);
  /* transform: translate3d(0px,0px,0px); */
    }
  
    to {
  
      transform: rotate(360deg);
  /* transform: translate3d(10px, 10px, 10px); */

  
    }
  
  }