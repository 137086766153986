.cool-up-animation {
animation: up 1.5s ease-in-out .1s 1;
/* animation: name duration timing-function delay iteration-count direction fill-mode; */
}
@keyframes up {
0% {
    transform: translateY(20%);
}    
100% {
    transform: translateY(-80%);

}
}