@keyframes float {
    0% {
       /* box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6); */
        transform: translatey(0px);
   }
    50% {
       /* box-shadow: 0 25px 15px 0px rgba(0, 0, 0, 0.2); */
        transform: translatey(-20px);
   }
    100% {
        /* box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6); */
        transform: translatey(0px);
   }
}
.avatar {
    width: 150px;
    height: 150px;
    box-sizing: border-box;  /* Not needed */
  /*   
    border: 5px white solid;
    border-radius: 50%;
    */ 
background: transparent;
  overflow: hidden;
   /* box-shadow: 0 5px 15px 0px rgba(0, 0, 0, 0.6); */
    transform: translatey(0px);
    animation: float 6s ease-in-out infinite;
}
.avatar img {
    width: 100%;
    height: auto;
}
.wave {
    animation-name: wave-animation; /* Refers to the name of your @keyframes element below */
    animation-duration: 2.5s; /* Change to speed up or slow down */
    animation-iteration-count: infinite; 
    transform-origin: 70% 70%; /* Pivot around the bottom-left palm */
    display: inline-block;
  }
  
  @keyframes wave-animation {
    0% {
      transform: rotate(0deg);
    }
    10% {
      transform: rotate(14deg);
    } /* The following five values can be played with to make the waving more or less extreme */
    20% {
      transform: rotate(-8deg);
    }
    30% {
      transform: rotate(14deg);
    }
    40% {
      transform: rotate(-4deg);
    }
    50% {
      transform: rotate(10deg);
    }
    60% {
      transform: rotate(0deg);
    } /* Reset for the last half to pause */
    100% {
      transform: rotate(0deg);
    }
  }
  